import utils from '@2l/utils';
import helpers from './helpers';

const { env } = utils;
const { uuid, hash } = helpers;

const PIXEL_ID = env('PIXEL_ID', '');

const PIXEL_BASE_URL = env('PIXEL_BASE_URL', 'https://www.facebook.com/tr');

async function createPixel() {
  if (!PIXEL_ID)
    return;

  const location = encodeURIComponent(window.location.href);
  const externalId = window.EXTERNAL_USER_ID;
  const eventId = uuid();

  const query = new URLSearchParams(window.location.search);

  const fbc = query.get('fbclid');
  const fbp = getFbc();

  const params = {
    ev: 'ViewContent',
    rqm: 'GET',
    noscript: '1',

    id: PIXEL_ID,
    dl: location,
    rl: location,
    ts: Date.now(),

    ['cd[external_id]']: externalId,
    ['cd[event_id]']: eventId,

    ['ud[external_id]']: await hash(externalId),
    ['ud[event_id]']: await hash(eventId),
  };

  if (fbp)
    params.fbp = fbp;

  if (fbc)
    params.fbc = fbc;

  const img = document.createElement('img');
  const url = new URL(PIXEL_BASE_URL);

  const promise = new Promise((resolve, reject) => {
    img.onload = resolve;
    img.onerror = reject;
  });

  url.search = new URLSearchParams(params).toString();

  document.body.appendChild(img);

  img.style.display = 'none';
  img.src = url.toString();

  return promise;
}

function getFbc() {
  let result = /_fbp=(fb\.1\.\d+\.\d+)/.exec(window.document.cookie);
  if (!(result && result[1]))
    return null;

  return result[1];
}

export default { createPixel };
