import utils from '@2l/utils';
import { createI18n } from 'vue3-i18n';

const { env, flatify } = utils;

const defaultLang = env('DEFAULT_LANG', 'en');

const browserLang = navigator.language && navigator.language.slice(0,2);
const params = new URLSearchParams(location.search);

const selectedLanguage = params.get('language') || browserLang || defaultLang;

var locale;

try {
  locale = require(`../locale/${selectedLanguage}.json`);
} catch(e) {
  locale = require(`../locale/${defaultLang}.json`);
}

const i18n = createI18n({
  locale: selectedLanguage,
  messages: {
    [selectedLanguage]: replaceDotation(locale)
  }
});

export default i18n;

function replaceDotation(locale) {
  const index = {};
  const messages = flatify(locale);

  for (const key in messages)
    index[key.replace('.', '_')] = messages[key];

  return index;
}
