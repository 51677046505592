import axios from '@/lib/axios';

export default {
  async reserve(context, externalId) {
    return axios.post('/api/v16/users/reserve', {
      externalId,
      eventSourceUrl: window.location.href
    });
  }
};
