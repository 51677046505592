const scheme = require('@/data/fb_map.json');
const utils = require('@2l/utils');

const { G, all, not } = utils;

const retargeting = {
  pid: 1,
  c: 1,
  af_prt: 1,
  af_mp: 1,
  clickid: 1,
  click_id: 1,
  af_siteid: 1,
  af_sub_siteid: 1,
  af_c_id: 1,
  af_adset: 1,
  af_adset_id: 1,
  af_ad: 1,
  af_ad_id: 1,
  af_ad_type: 1,
  af_click_lookback: 1,
  af_viewthrough_lookback: 1,
  af_channel: 1,
  af_keywords: 1,
  af_cost_model: 1,
  af_cost_currency: 1,
  af_cost_value: 1,
  af_r: 1,
  af_web_dp: 1,
  af_dp: 1,
  af_force_deeplink: 1,
  af_ref: 1,
  is_incentivized: 1,
  af_param_forwarding: 1,
  af_os: 1,
  af_model: 1,

  is_retargeting: 1,
  af_reengagement_window: 1,
};

const advertisting = {
  af_video_total_length: 1,
  af_video_played_length: 1,
  af_playable_played_length: 1,
  af_ad_time_viewed: 1,
  af_ad_displayed_percent: 1,
  af_audio_total_length: 1,
  af_audio_played_length: 1,
};

const android = {
  advertising_id: 1,
  sha1_advertising_id: 1,
  md5_advertising_id: 1,
  android_id: 1,
  sha1_android_id: 1,
  md5_android_id: 1,
  imei: 1,
  sha1_imei: 1,
  md5_imei: 1,
  oaid: 1,
  sha1_oaid: 1,
  md5_oaid: 1,
  af_android_url: 1,
  sha1_el: 1,
  fire_advertising_id: 1,
};

const ios = {
  idfa: 1,
  idfv: 1,
  af_ios_url: 1,

  af_ios_fallback: 1,
  sha1_idfa: 1,
  sha1_idfv: 1,
  mac: 1,
  md5_idfv: 1,
  sha1_mac: 1
};

const supportedQuery = {
  ...retargeting,
  ...advertisting,
  ...android,
  ...ios
};

const BASE_ONE_LINK = process.env.BASE_ONE_LINK;
const BASE_GOOGLE_LINK = process.env.BASE_GOOGLE_LINK;

const urlBySource = {
  social_facebook: BASE_ONE_LINK,
  web_google: BASE_GOOGLE_LINK
};

const shouldAssignIds = all(
  not(all(
    G('af_c_id'),
    G('af_adset_id'),
    G('af_ad_id')
  )),
  all(
    G('c'),
    G('af_adset'),
    G('af_ad')
  )
);

function build(query = {}) {
  const { redirect, mediaSource = '' } = query;

  if (redirect)
    return redirect;

  const target = urlBySource[mediaSource.toLowerCase()] || BASE_ONE_LINK;

  const index = {};
  const url = new URL(target);

  if (shouldAssignIds(query)) {
    const { c, af_adset, af_ad } = query;
    const key = [c, af_adset, af_ad].join('.');

    const { campaignId = '', adsetId = '', adId = '' } = scheme[key] || {};

    if (campaignId)
      index['af_c_id'] = campaignId;
    if (adsetId)
      index['af_adset_id'] = adsetId;
    if (adId)
      index['af_ad_id'] = adId;
  }

  for (const key in query)
    if (supportedQuery[key])
      index[key] = query[key];

  const params = new URLSearchParams(index);
  url.search = params.toString();

  return decodeURIComponent(url.toString());
}


export default { build };

