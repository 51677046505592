import { createWebHistory, createRouter } from 'vue-router';

import Download from '@/pages/download/download.vue';

const routes = [
  {
    path: '/download',
    name: 'download',
    component: Download
  },

  {
    path: '/',
    redirect: '/download'
  }
];

const router = createRouter({ history: createWebHistory(), routes });

router.beforeEach((to, from, next) => {
  if (to.meta.title)
    document.title = to.meta.title;

  if(!hasQueryParams(to) && hasQueryParams(from))
    next({name: to.name, query: from.query});
  else
    next();
});

export default router;


function hasQueryParams(route) {
  return !!Object.keys(route.query).length
}
