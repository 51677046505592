import Axios from 'axios';
import utils from '@2l/utils';

const {
  env,
  set
} = utils;

const PLATFORM_ID = env(
  'PLATFORM_ID', '23dbdf02-870b-4c3e-9e1c-3586245f115f');

var instance;

function getInstance() {
  if (instance)
    return instance;

  const common = {
    'Accept': 'application/json',
    'X-Platform-Id': PLATFORM_ID,
    'X-Requested-With': 'XMLHttpRequest'
  };

  const sessionId = localStorage.getItem('sessionId');

  if (sessionId)
    set(common, 'X-Session-Id', sessionId);

  instance = Axios.create({
    withCredentials: true,
    baseURL: process.env.BASE_API_URL,
    headers: { common }
  });

  return instance;
}

export default getInstance();
