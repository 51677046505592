<template>
  <article class="download">
    <div class="container">

      <a :href="url">
        <div class="container-logo" :class="{'kids': isKids, 'standard': !isKids}"></div>
      </a>
      <div class="container-download">
        <a v-if="store" id="download" :href="url">
          <div class="apple-store grey"></div>
        </a>
        <a v-if="!store" :href="url">
          <div class="btn green">
            {{$t('label_download')}}
          </div>
        </a>
      </div>
    </div>
  </article>
</template>

<script>
import appsflyer from '@/lib/appsflyer';
import facebook from '@/lib/facebook';

import { mapActions } from 'vuex';

const { createPixel } = facebook;
const edition = process.env.EDITION;

export default {
  name: 'download',
  methods: {
    ...mapActions('auth', ['reserve']),
  },

  data() {
    const query = this.$route.query || {};

    return {
      isKids: edition === 'Kids',
      url: appsflyer.build(query),
      store: !!query.store
    };
  },

  async mounted() {
    await createPixel();

    await this.reserve(window.EXTERNAL_USER_ID);
  }
};

</script>

<style lang="scss" scoped>
.download {
  display: flex;
  justify-content: center;
  align-items: center;

  a {
    text-decoration: none;
    color: black!important;
  }

  .btn {
    margin-bottom: 20px;
  }

  width: 100%;
  height: 100%;

  .container {
    width: 100%;
  }

  .container-download {
    max-width: 200px;
    margin: 0 auto;
  }

  .container-logo {
    background: url("../../assets/app-icon@2x.png") no-repeat;

    &.kids {
      background: url("../../assets/app-icon-kids@2x.png") no-repeat;
      background-size: contain
    }

    background-size: contain;
    width: 80px;
    height: 80px;

    margin: 20px auto 50px auto;
  }

  h1 {
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: 0px;
    text-align: center;

    color: #46494E;
  }


}
</style>
